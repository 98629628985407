// import { SahelInfoResponse } from '../dtos';

export const enum MapMode {
  SEARCH = 'search',
  CalculateDistance = 'calculate_distance',
  COMPOUND = 'compound'
}

export const enum ResultsType {
  COMPOUNDS = 'compounds',
  AREAS = 'areas'
}

export const enum ZoomType {
  ZoomIn = 'zoom-in',
  ZoomOut = 'zoom-out'
}

export type MapCoords = [lat: number, long: number];

export interface MapFilters {
  compoundsIds?: number[];
  hasGarden?: boolean;
  hasRoof?: boolean;
  maxUnitArea?: number;
  minUnitArea?: number;
  isNawyNow?: boolean;
  minPrice?: number;
  maxPrice?: number;
  maxDownPayment?: number;
  maxInstallments?: number;
  areaIds?: number[];
  developerIds?: number[];
  propertyTypeIds?: number[];
  propertyAmenityIds?: [number];
  numberOfBedrooms?: number[];
  numberOfBathrooms?: number[];
  resaleType?: string[];
  finishing?: string[];
  installmentYears?: number[];
  isDelivered?: boolean;
  readyBy?: number;
  minReadyBy?: number;
}

export interface MarkerLocation {
  id: number;
  name: string;
  longitude: number;
  latitude: number;
  type: MapMarkerType;
  category: 'compound' | 'area';
  coordinates: [];
}

export interface MapSearchStates {
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
}

export interface MapMarkersProps {
  locations: MarkerLocation[];
}

export interface DefaultMapEntity {
  id: number;
  name: string;
  image: string;
  long: number;
  lat: number;
  geometry?: {
    type: string;
    coordinates: MapCoords[][] | MapCoords[] | MapCoords[][][] | null;
  };
  isFiltered: boolean;
  parent_id?: number;
}

export type Compound = DefaultMapEntity & {
  minPrice: number;
};

export type Area = DefaultMapEntity;

export const enum PropertySaleType {
  All = 'all',
  Developer_Sale = 'developer_sale',
  Resale = 'resale'
}

export type Destination = 'a' | 'b';

export const enum QuickFilter {
  PRICE = 'price',
  DELIVERY = 'delivery',
  PROPERTY_TYPE = 'property_types',
  NAWY_NOW = 'nawy_now'
}

export const enum MapSearchFiltersTypes {
  compounds = 'compoundsIds',
  hasGarden = 'hasGarden',
  hasRoof = 'hasRoof',
  maxUnitArea = 'maxUnitArea',
  minUnitArea = 'minUnitArea',
  isNawyNow = 'isNawyNow',
  minPrice = 'minPrice',
  maxPrice = 'maxPrice',
  maxDownPayment = 'maxDownPayment',
  maxInstallments = 'maxInstallments',
  areas = 'areaIds',
  developers = 'developerIds',
  propertyTypes = 'propertyTypeIds',
  amenities = 'propertyAmenityIds',
  bedrooms = 'numberOfBedrooms',
  bathrooms = 'numberOfBathrooms',
  saleType = 'resaleType',
  finishing = 'finishing',
  installmentYears = 'installmentYears',
  isDelivered = 'isDelivered',
  readyByYears = 'readyBy',
  minReadyBy = 'minReadyBy'
}

export interface FilterChipProps {
  type?: QuickFilter;
  icon: JSX.Element;
  label: string;
  ref?: any
}

export enum MapMarkerType {
  DEFAULT = 'default',
  SELECTED = 'selected',
  FIRST_DESTINATION = 'first-destination',
  SECOND_DESTINATION = 'second-destination',
  UNFILTERED = 'unfiltered',
  AREA = 'area'
}
