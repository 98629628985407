import { useContext } from 'react';
import {
  SearchContext,
  SearchContextInterface
} from '../../context/SearchContext';
import { FilterStyles } from './FilterWrapper';
import { SearchFiltersTypes } from '../../../../reducers/search-filters/reducer';
import { useSelector } from 'react-redux';
import MobileFiltersMenuPresentation from './MobileFiltersMenuPresentation';
import FiltersMenuPresentation from './FiltersMenuPresentation';
import { useTranslation } from 'next-i18next';
import { isEqual } from '../../../../helpers/isEqual';
import { useRouter } from 'next/router';

interface FilterObject {
  name: string;
  options: any;
  filterStyle:
    | {
        mobile: FilterStyles | null;
        desktop: FilterStyles | null;
      }
    | {
        mobile: FilterStyles | null;
        desktop: FilterStyles | null;
      }[];
  type:
    | SearchFiltersTypes
    | { min: SearchFiltersTypes; max: SearchFiltersTypes }
    | { delivery_date: SearchFiltersTypes; delivery_years: SearchFiltersTypes }
    | {
        down_payment: SearchFiltersTypes;
        monthly_installments: SearchFiltersTypes;
        installment_years: SearchFiltersTypes;
      };
  withReset?: boolean;
  step?: number;
  prefix?: string;
  withSeeMore?: boolean;
  singleSelect?: boolean;
}

export interface FilterMenuProps {
  filters: FilterObject[];
  btnText?: string;
  searchResultsCount?: number;
}
interface FiltersMenuContainerProps {
  areas?: any;
  developers?: any;
}
const FiltersMenuContainer = ({
  areas,
  developers
}: FiltersMenuContainerProps) => {
  const { filterOptions, searchFiltersState } = useContext(
    SearchContext
  ) as SearchContextInterface;
  const searchResults = useSelector(
    (state: any) => state.searchResults.searchResults,
    isEqual
  );
  const { asPath } = useRouter();
  const { t } = useTranslation(['search', 'common']);
  const isMobile = useSelector((state: any) => state.app.isMobile);
  const mapToFilterOptions = (filterOptions: []) => {
    return filterOptions?.map((option: any) => ({
      value: option.id,
      options: { displayedValue: option.name }
    }));
  };
  const areaFilter = {
    name: 'areas',
    options: mapToFilterOptions(areas),
    filterStyle: {
      mobile: asPath.includes('/nawy-now') ? null : FilterStyles.DRAWER_SELECT,
      desktop: FilterStyles.CHECKBOX
    },
    type: SearchFiltersTypes.AREAS,
    withReset: true,
    withSeeMore: true
  };
  const developerFilter = {
    name: 'developers',
    options: mapToFilterOptions(developers),
    filterStyle: {
      mobile: asPath.includes('/nawy-now') ? null : FilterStyles.DRAWER_SELECT,
      desktop: FilterStyles.CHECKBOX
    },
    type: SearchFiltersTypes.DEVELOPERS,
    withReset: true,
    withSeeMore: true
  };
  const propertyTypesFilter = {
    name: 'property_types',
    options: filterOptions?.property_types,
    filterStyle: {
      mobile: FilterStyles.CHIP_SELECT,
      desktop: FilterStyles.CHECKBOX
    },
    type: SearchFiltersTypes.PROPERTY_TYPES,
    withReset: true
  };
  const bedroomsFilter = {
    name: 'bedrooms',
    options: filterOptions?.bedrooms,
    filterStyle: {
      mobile: FilterStyles.NUMBER_SELECT,
      desktop: FilterStyles.NUMBER_SELECT
    },
    type: SearchFiltersTypes.BEDROOMS
  };
  const bathroomsFilter = {
    name: 'bathrooms',
    options: filterOptions?.bathrooms,
    filterStyle: {
      mobile: FilterStyles.NUMBER_SELECT,
      desktop: FilterStyles.NUMBER_SELECT
    },
    type: SearchFiltersTypes.BATHROOMS
  };
  const finishingTypeFilter = {
    name: 'finishing',
    options: filterOptions?.finishing,
    filterStyle: {
      mobile: FilterStyles.CHIP_SELECT,
      desktop: FilterStyles.CHECKBOX
    },
    type: SearchFiltersTypes.COMPLETION_TYPE,
    withReset: true
  };
  const deliveryDateFilter = {
    name: 'delivery_date',
    options: filterOptions?.delivery_date,
    filterStyle: {
      mobile: FilterStyles.DELIVERY,
      desktop: FilterStyles.DELIVERY
    },
    type: {
      delivery_date: SearchFiltersTypes.DELIVERY_DATE,
      delivery_years: SearchFiltersTypes.DELIVERY_YEARS
    },
    withReset: !searchFiltersState.searchFilters.nawy_now
  };
  const saleTypeFilter = {
    name: 'sale_type',
    options: [
      {
        value: 'developer_sale',
        options: { displayedValue: 'developer_sale' }
      },
      { value: 'resale', options: { displayedValue: 'resale' } }
    ],
    filterStyle: {
      mobile: FilterStyles.CHIP_SELECT,
      desktop: FilterStyles.CHECKBOX
    },
    type: SearchFiltersTypes.SALE_TYPE,
    withReset: true
  };
  const nawyNowFilter = {
    name: '',
    options: [{ value: 'nawy_now', options: { displayedValue: '' } }],
    filterStyle: {
      mobile: FilterStyles.NAWY_NOW,
      desktop: FilterStyles.NAWY_NOW
    },
    type: SearchFiltersTypes.NAWY_NOW
  };
  const priceFilter = {
    name: 'price',
    options: { min: 500000, max: 25000000 },
    filterStyle: {
      mobile: FilterStyles.RANGE,
      desktop: FilterStyles.RANGE
    },
    type: {
      min: SearchFiltersTypes.MIN_PRICE,
      max: SearchFiltersTypes.MAX_PRICE
    },
    withReset: true,
    step: 50000
  };
  const unit_areaFilter = {
    name: 'unit_area',
    options: { min: 50, max: 400 },
    filterStyle: {
      mobile: FilterStyles.RANGE,
      desktop: FilterStyles.RANGE
    },
    type: {
      min: SearchFiltersTypes.MIN_UNIT_AREA,
      max: SearchFiltersTypes.MAX_UNIT_AREA
    },
    withReset: true,
    prefix: 'm2',
    step: 10
  };
  const paymentsFilter = {
    name: 'payment_plans',
    options: {
      down_payment: filterOptions?.down_payment,
      monthly_installments: filterOptions?.monthly_installment,
      installment_years: filterOptions?.installment_years
    },
    filterStyle: {
      mobile: FilterStyles.PAYMENT,
      desktop: FilterStyles.PAYMENT
    },

    type: {
      down_payment: SearchFiltersTypes.DOWN_PAYMENT,
      monthly_installments: SearchFiltersTypes.MONTHLY_INSTALLMENTS,
      installment_years: SearchFiltersTypes.INSTALLMENT_YEARS
    },
    withReset: true
  };

  const amenitiesFilter = {
    name: 'amenities',
    options: filterOptions?.amenities,
    filterStyle: {
      mobile: FilterStyles.CHIP_SELECT,
      desktop: FilterStyles.CHECKBOX
    },
    type: SearchFiltersTypes.AMENITIES,
    withReset: true
  };

  const desktopFilters = [
    areaFilter,
    developerFilter,
    bedroomsFilter,
    bathroomsFilter,
    propertyTypesFilter,
    finishingTypeFilter,
    priceFilter,
    paymentsFilter,
    deliveryDateFilter,
    unit_areaFilter,
    saleTypeFilter,
    nawyNowFilter,
    amenitiesFilter
  ];

  const mobileFilters = [
    areaFilter,
    developerFilter,
    propertyTypesFilter,
    bedroomsFilter,
    bathroomsFilter,
    finishingTypeFilter,
    paymentsFilter,
    deliveryDateFilter,
    saleTypeFilter,
    nawyNowFilter,
    priceFilter,
    unit_areaFilter,
    amenitiesFilter
  ];

  const getApplyBtnText = () => {
    if (searchResults.loadedSearchResultsCount === 0) {
      return t('no_results', { ns: 'common' });
    } else {
      return `${t('find')} ${searchResults.loadedSearchResultsCount} ${t(
        'results'
      )}`;
    }
  };

  return isMobile ? (
    <MobileFiltersMenuPresentation
      filters={mobileFilters}
      btnText={getApplyBtnText()}
      searchResultsCount={searchResults.loadedSearchResultsCount}
    />
  ) : (
    <FiltersMenuPresentation
      filters={desktopFilters}
      btnText={getApplyBtnText()}
      searchResultsCount={searchResults.loadedSearchResultsCount}
    />
  );
};

export default FiltersMenuContainer;
