import { Map } from '../../new-lib/types/common';
import { ObjectOptionListValues } from './types/ObjectOptionListValues';
import { OptionListValues } from './types/OptionListValues';
import { RangeOptionListValues } from './types/RangeOptionListValues';
import { SearchFiltersTypes } from '../../reducers/search-filters/reducer';

export const getNowDate = () => {
  const date = new Date();

  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so +1
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

export const mapOptionsToNewForm = (options: any) => {
  let newOptions: Map = {};
  const showOptions = ['property', 'compound'];
  newOptions[SearchFiltersTypes.CATEGORY] = new OptionListValues(
    showOptions
  ).outputOptions;
  if (options && options.finishings) {
    const finishingsOptions = options.finishings;
    newOptions[SearchFiltersTypes.COMPLETION_TYPE] = new OptionListValues(
      finishingsOptions
    ).outputOptions;
  }
  if (options && options.sale_types) {
    const saleType = options.sale_types;
    const createdoptions = { move_now_pay_later: 'nawy_now' };
    newOptions[SearchFiltersTypes.SALE_TYPE] = new OptionListValues(
      saleType,
      undefined,
      createdoptions
    ).outputOptions;
  }
  if (options && options.min_price_list) {
    newOptions[SearchFiltersTypes.MIN_PRICE] = new OptionListValues(
      options.min_price_list
    ).outputOptions;
  }
  if (options && options.max_price_list) {
    newOptions[SearchFiltersTypes.MAX_PRICE] = new OptionListValues(
      options.max_price_list
    ).outputOptions;
  }
  if (options && options.installment_years_list) {
    const installmentYearsOptions = options.installment_years_list;
    newOptions[SearchFiltersTypes.INSTALLMENT_YEARS] = new OptionListValues(
      installmentYearsOptions
    ).outputOptions;
  }
  if (options && options.down_payment_list) {
    const downPaymentOptions = options.down_payment_list;
    newOptions[SearchFiltersTypes.DOWN_PAYMENT] = new OptionListValues(
      downPaymentOptions
    ).outputOptions;
  }
  if (options && options.installments_list) {
    const installmentsOptions = options.installments_list;
    newOptions[SearchFiltersTypes.MONTHLY_INSTALLMENTS] = new OptionListValues(
      installmentsOptions
    ).outputOptions;
  }
  if (options && options.min_bedrooms && options.max_bedrooms) {
    const bedroomsOptions = {
      min: options.min_bedrooms,
      max: options.max_bedrooms
    };
    newOptions[SearchFiltersTypes.BEDROOMS] = new RangeOptionListValues(
      bedroomsOptions
    ).outputOptions;
  }
  if (options && options.min_bathrooms && options.max_bathrooms) {
    const bathroomsOptions = {
      min: options.min_bathrooms,
      max: options.max_bathrooms
    };
    newOptions[SearchFiltersTypes.BATHROOMS] = new RangeOptionListValues(
      bathroomsOptions
    ).outputOptions;
  }
  if (options && options.min_unit_area && options.max_unit_area) {
    newOptions[SearchFiltersTypes.MIN_UNIT_AREA] = new OptionListValues([
      options.min_unit_area
    ]).outputOptions;
    newOptions[SearchFiltersTypes.MAX_UNIT_AREA] = new OptionListValues([
      options.max_unit_area
    ]).outputOptions;
  }
  if (options && options.amenities) {
    const amenitiesOptions = options.amenities;
    newOptions[SearchFiltersTypes.AMENITIES] = new ObjectOptionListValues(
      amenitiesOptions,
      { value: 'id', displayedValue: 'name', image: 'image_path' }
    ).outputOptions;
  }

  if (options && options.max_ready_by) {
    const deliveryDateOptions = createDeliveryDateOption(options.max_ready_by);
    newOptions[SearchFiltersTypes.DELIVERY_DATE] = new ObjectOptionListValues(
      deliveryDateOptions,
      { value: 'value', displayedValue: 'display' }
    ).outputOptions;
  }
  if (options && options.property_types) {
    const propertyTypes = options.property_types;
    newOptions[SearchFiltersTypes.PROPERTY_TYPES] = new ObjectOptionListValues(
      propertyTypes,
      { value: 'id', displayedValue: 'name', image: 'icon.url' }
    ).outputOptions;
  }
  if (options && options.sortings) {
    const sortings = options.sortings;
    newOptions[SearchFiltersTypes.SORT_BY] = new ObjectOptionListValues(
      sortings,
      { value: 'key', displayedValue: 'value', direction: 'direction' }
    ).outputOptions;
  }
  return newOptions;
};

const createDeliveryDateOption = (deliveryOption: string): object[] => {
  const options = [];
  const deliveryYear = parseInt(deliveryOption.split('-')[0], 10);
  const currentYear = new Date().getFullYear();

  for (let year = deliveryYear; year >= currentYear; year--) {
    if (deliveryYear === year) {
      options.push({ value: year, display: `${year}+` });
    } else {
      options.push({ value: year, display: `${year}` });
    }
  }
  options.push({ value: getNowDate(), display: 'delivered' });

  return options.reverse();
};

export const isValidDate = (dateStr: string) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateStr.match(regex)) {
    // Doesn't match the pattern YYYY-MM-DD
    return false;
  }

  const date = new Date(dateStr);
  const timestamp = date.getTime();

  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    // Date is not valid
    return false;
  }

  return date.toISOString().startsWith(dateStr);
};
