import { useContext, useEffect, useRef, useState } from 'react';
import styled, {
  css,
  DefaultTheme,
  keyframes,
  ThemeContext
} from 'styled-components';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import SearchBar from '../../../features/search-new/components/search-bar/SearchBar';
import { containsAnyWord } from '../../../helpers/common';
import Nawy from '../../../public/assets/icons/common/nawy.svg';
import Button from '../../ui/Button';
import {
  SearchContext,
  SearchContextInterface
} from '../../../features/search-new/context/SearchContext';
import HeaderMenu from './HeaderMenu';
import MenuBurgerIcon from '../../svg-icons/MenuBurgerIcon';
import { AdjustLinkService } from '../../../new-lib/dynamic-link';
import FiltersMenuContainer from '../../../features/search-new/components/filters-menu/FiltersMenuContainer';
import { Sheet, SheetRef } from 'react-modal-sheet';

interface Props {
  areas?: any;
  developers?: any;
  handleNavbarItemClick: (eventName: string, eventFeature: string) => void;
}

const MobileHeader = ({ handleNavbarItemClick, areas, developers }: Props) => {
  const [rotate, setRotate] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(['common', 'home']);
  const router = useRouter();
  const { asPath: path, locale } = router;
  const theme = useContext(ThemeContext) as DefaultTheme;
  const { showUniversalSearch, showFilters, setShowFilters } = useContext(
    SearchContext
  ) as SearchContextInterface;
  const openApp = () => {
    handleNavbarItemClick('get_app', 'other');
    AdjustLinkService.handleOpenApp(router.asPath);
  };

  const currPath = path.split('?');
  const sheetRef = useRef<SheetRef>(null);
  const isHome = currPath[0]?.endsWith('/mobile') || currPath[0] === '/';

  useEffect(() => {
    if (isOpen || showFilters) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen, showFilters]);

  const getHeaderContainer = () => {
    return (
      <HeaderContainer data-test="mobile-navbar">
        <ul>
          <li
            data-test="burger-icon"
            onClick={() => {
              setRotate(!rotate);
              setIsOpen(!isOpen);
              handleNavbarItemClick('burger_menu', 'header');
            }}
          >
            <MenuBurgerIcon height={16} />
          </li>
          <Link itemProp="url" href="/" locale={locale} prefetch legacyBehavior>
            <li
              itemProp="logo"
              className="header-logo"
              onClick={() =>
                handleNavbarItemClick('navbar_nawy_logo', 'header')
              }
            >
              <Image
                src={Nawy}
                alt="Nawy"
                style={{ width: '79px', height: '20px', objectFit: 'cover' }}
              />
            </li>
          </Link>
          <li
            className="get-app"
            itemScope
            itemType="http://schema.org/Service"
          >
            <Button
              backgroundColor={theme.light.colors.primaryBlue}
              border={'none'}
              color={theme.light.colors.white}
              fontSize={theme.font.sizes.s}
              onClick={openApp}
              padding={'5px 8px'}
              text={t('get_app')}
            />
          </li>
        </ul>
      </HeaderContainer>
    );
  };

  return (
    <div id="header">
      {isHome && !showUniversalSearch ? (
        getHeaderContainer()
      ) : (
        <StickyContainer id="header-search-container">
          {getHeaderContainer()}

          {!containsAnyWord(path, [
            'cityscape',
            'compare',
            'map',
            'nawy-now',
            'verify-agent',
            'nawy-unlocked',
            'favorites',
            'sell-my-property',
            'about-us',
            'terms',
            'privacy-policy'
          ]) && (
            <SearchContainer>
              <SearchBar />
            </SearchContainer>
          )}
        </StickyContainer>
      )}
      {isOpen && (
        <Container className={isOpen ? 'open' : ''} dir={locale}>
          <HeaderMenu
            handleNavbarItemClick={handleNavbarItemClick}
            setIsOpen={setIsOpen}
          />
        </Container>
      )}
      <Sheet
        ref={sheetRef}
        isOpen={showFilters}
        onClose={() => setShowFilters(false)}
        disableScrollLocking
      >
        <Sheet.Container
          style={{
            backgroundColor: '#FBFCFE'
          }}
        >
          <Sheet.Header style={{ paddingTop: '8px' }} />
          <Sheet.Content
            style={{
              width: '100%',
              direction: locale == 'ar' ? 'rtl' : 'ltr'
            }}
            disableDrag
          >
            <Sheet.Scroller
              style={{
                gap: '16px',
                direction: locale === 'ar' ? 'rtl' : 'ltr'
              }}
              className="hide-scrollbar"
              draggable={false}
            >
              <FiltersMenuContainer areas={areas} developers={developers} />
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
      </Sheet>
    </div>
  );
};

const StickyContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
`;

const HeaderContainer = styled.div`
  height: 60px;
  width: 100%;
  background-color: ${props => props.theme.light.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;

  ul {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    justify-content: space-between;

    .header-logo {
      justify-content: center;
    }

    li {
      display: flex;
      width: calc(100% / 3);

      &.get-app {
        justify-content: flex-end;
        button {
          height: 30px;
          border-radius: 10px;
          font-family: materialMedium;
        }
      }
    }

    .burger-icon {
      transition: transform 0s ease-in-out;
      &.rotate {
        transform: rotate(180deg);
      }
    }
  }
`;

// Animation for LTR layout
const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

// Animation for RTL layout
const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const Container = styled.div<{ dir: string | undefined }>`
  position: fixed;
  top: 0;
  left: 0;
  height: auto;
  width: 100%;
  background-color: ${props => props.theme.light.colors.white};
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 99999999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 25px;
  opacity: 0;
  ${({ dir }) =>
    dir === 'ar'
      ? css`
          right: 0;
          animation: ${slideInFromRight} 0.1s ease forwards;
        `
      : css`
          left: 0;
          animation: ${slideInFromLeft} 0.1s ease forwards;
        `}
  will-change: transform, opacity;
`;

const SearchContainer = styled.div`
  position: fixed;
  width: 100%;
  top: 60px;
  left: 0;
  z-index: 99999;
  height: 75px;
`;

export default MobileHeader;
