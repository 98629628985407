import { useRouter } from 'next/router';
import { SearchFiltersActionTypes } from '../../../../../reducers/search-filters/actions';
import { SearchFiltersTypes } from '../../../../../reducers/search-filters/reducer';
import { updateQuery } from '../../../../../helpers/common';
import { InputOption } from '../../../../../new-lib/types/search-filters';
import { useContext } from 'react';
import CheckboxPresentation from './CheckboxPresentation';
import { logEvent } from '../../../../../new-lib/analytics';
import { getNowDate } from '../../../../../helpers/search-options/common';
import {
  SearchContext,
  SearchContextInterface
} from '../../../../search-new/context/SearchContext';
import { SearchViewTypes } from '../../../../../new-lib/constants/common';

export type CheckBoxFilterTypes =
  | SearchFiltersTypes.HAS_GARDEN
  | SearchFiltersTypes.HAS_ROOF
  | SearchFiltersTypes.NAWY_NOW;

interface ContainerProps {
  options: InputOption[];
}

const CheckBoxContainer = ({ options }: ContainerProps): JSX.Element => {
  const { searchFiltersState, searchFiltersDispatch } = useContext(
    SearchContext
  ) as SearchContextInterface;
  const router = useRouter();

  const handleChange = (filterType: CheckBoxFilterTypes) => {
    const checked = !searchFiltersState.searchFilters[filterType];
    searchFiltersDispatch({
      type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
      payload: {
        name: filterType,
        value: checked
      }
    });

    if (filterType === SearchFiltersTypes.NAWY_NOW) {
      if (checked) {
        searchFiltersDispatch({
          type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
          payload: {
            name: SearchFiltersTypes.DELIVERY_DATE,
            value: getNowDate()
          }
        });
        searchFiltersDispatch({
          type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
          payload: {
            name: SearchFiltersTypes.DELIVERY_YEARS,
            value: []
          }
        });
      } else {
        searchFiltersDispatch({
          type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
          payload: {
            name: SearchFiltersTypes.DELIVERY_DATE,
            value: undefined
          }
        });
      }
    }

    if (checked && router.query?.view !== SearchViewTypes.MAP)
      updateQuery(router, [{ name: filterType, value: checked }], []);
    else if (router.query?.view !== SearchViewTypes.MAP) {
      updateQuery(router, [], [filterType]);
    }

    logEvent.search(
      `filter_${filterType}`,
      'filtersMenu',
      'filter',
      searchFiltersState,
      { [filterType]: checked }
    );
    if (checked && router.query?.view !== SearchViewTypes.MAP)
      updateQuery(
        router,
        [
          { name: filterType, value: checked },
          ...(!router.asPath.includes('/nawy-now')
            ? [{ name: 'page_number', value: 1 }]
            : [])
        ],
        []
      );
    else if (router.query?.view !== SearchViewTypes.MAP) {
      updateQuery(
        router,
        [
          ...(!router.asPath.includes('/nawy-now')
            ? [{ name: 'page_number', value: 1 }]
            : [])
        ],
        [filterType]
      );
    }
  };

  return <CheckboxPresentation options={options} handleChange={handleChange} />;
};

export default CheckBoxContainer;
