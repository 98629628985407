import styled from 'styled-components';
import NawyNow from '../../../../../new-components/svg-icons/NawyNow';
import { useTranslation } from 'next-i18next';
import { useContext } from 'react';
import {
  SearchContext,
  SearchContextInterface
} from '../../../context/SearchContext';
import { SearchFiltersActionTypes } from '../../../../../reducers/search-filters/actions';
import { SearchFiltersTypes } from '../../../../../reducers/search-filters/reducer';
import { getNowDate } from '../../../../../helpers/search-options/common';
import { useRouter } from 'next/router';
import { updateQuery } from '../../../../../helpers/common';
import { logEvent } from '../../../../../new-lib/analytics';
import { SearchViewTypes } from '../../../../../new-lib/constants/common';

const NawyNowFilter = () => {
  const { t } = useTranslation('search');
  const { searchFiltersState, searchFiltersDispatch } = useContext(
    SearchContext
  ) as SearchContextInterface;
  const router = useRouter();
  const nowDate = getNowDate();
  const handleChange = () => {
    const checked = !searchFiltersState.searchFilters.nawy_now;

    searchFiltersDispatch({
      type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
      payload: {
        name: SearchFiltersTypes.NAWY_NOW,
        value: checked
      }
    });

    if (checked) {
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
        payload: {
          name: SearchFiltersTypes.DELIVERY_DATE,
          value: getNowDate()
        }
      });
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
        payload: {
          name: SearchFiltersTypes.DELIVERY_YEARS,
          value: []
        }
      });
      if (router.query?.view !== SearchViewTypes.MAP) {
        updateQuery(
          router,
          [
            { name: SearchFiltersTypes.DELIVERY_DATE, value: nowDate },
            { name: SearchFiltersTypes.NAWY_NOW, value: true }
          ],
          [SearchFiltersTypes.DELIVERY_YEARS]
        );
      }
    } else {
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_SINGLE_VALUE,
        payload: {
          name: SearchFiltersTypes.DELIVERY_DATE,
          value: undefined
        }
      });
      searchFiltersDispatch({
        type: SearchFiltersActionTypes.UPDATE_FILTERS_WITH_MULTISELECT,
        payload: {
          name: SearchFiltersTypes.DELIVERY_YEARS,
          value: []
        }
      });
      if (router.query?.view !== SearchViewTypes.MAP) {
        updateQuery(router, undefined, [
          SearchFiltersTypes.DELIVERY_DATE,
          SearchFiltersTypes.DELIVERY_YEARS,
          SearchFiltersTypes.NAWY_NOW
        ]);
      }
    }

    logEvent.search(
      `filter_${'nawy_now'}`,
      'filtersMenu',
      'filter',
      searchFiltersState,
      { ['nawy_now']: checked }
    );
  };

  return (
    <Container>
      <div className="filter-info">
        <NawyNow />

        <div className="text-info">
          <h2>Nawy Now</h2>
          <span>{t('nawy_now_subtitle')}</span>
        </div>
      </div>

      <input
        type={'checkbox'}
        name={'nawy_now'}
        id={'nawy_now'}
        onChange={handleChange}
        checked={!!searchFiltersState.searchFilters.nawy_now}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  .filter-info {
    display: flex;
    align-items: center;
    gap: 16px;

    .text-info {
      h2 {
        color: #000;
        font-size: 16px;
        font-family: materialBold;
      }
      span {
        font-size: 12px;
        color: #475467;
      }
    }
  }
  input[type='checkbox'] {
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    cursor: pointer;
    transform: translateY(-0.075em);
    &:not(:checked) {
      border: 1px solid #d3dbe6;
      background-color: #f7f9fd;
    }
    &:checked {
      background-color: #015c9a;
    }

    &::before {
      content: '';
      min-width: 13px;
      min-height: 13px;
      border-radius: 4px;
      transform: scale(0);
      transition: 0.2s transform ease-in-out;
    }

    &:checked::before {
      border: none;
      background-color: #f7f9fd;
      transform: scale(1);
      transform-origin: bottom left;
      clip-path: polygon(40% 78%, 88% 17%, 95% 23%, 42% 90%, 8% 62%, 14% 56%);
    }
  }

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.mobile.max}) {
    /* padding: 16px; */
    border-radius: 8px;

    .filter-info {
      gap: 8px;
    }
  }
`;

export default NawyNowFilter;
