import styled from 'styled-components';
import Image, { StaticImageData } from 'next/image';
import { useCallback, useEffect, useState } from 'react';
import { getWebpSrc } from '../helpers/images';

interface MainProps {
  src: string | StaticImageData;
  alt: string;
  objectFit?: string;
  sizes?: string;
  quality?: number;
  priority?: boolean;
  placeholder?: 'blur' | 'empty';
  loading?: 'eager' | 'lazy';
  style?: any;
  className?: string;
  dataTest?: string;
  withSkeleton?: boolean;
  onClick?: () => void;
  imageSize?: 'default' | 'vLow' | 'low' | 'medium' | 'high'; // Original size || 100px || 300px || 600px || 1000px
}
interface ImageWithDimensions extends MainProps {
  width: number;
  height: number;
  fill?: never;
}

interface ImageWithFill extends MainProps {
  fill: boolean;
  width?: never;
  height?: never;
}

type ImageProps = ImageWithDimensions | ImageWithFill;
interface StyledImagePropsType {
  imageLoaded?: boolean;
  withSkeleton?: boolean;
}

const ImageSkeleton = ({ imageLoaded }: StyledImagePropsType) => {
  return <Skeleton imageLoaded={imageLoaded}></Skeleton>;
};

const ImageWrapper = ({
  src,
  style,
  className,
  objectFit,
  alt,
  fill,
  width,
  height,
  dataTest,
  priority,
  quality,
  placeholder,
  sizes,
  withSkeleton,
  onClick,
  imageSize,
  loading
}: ImageProps): any => {
  style = style ?? {};
  if (objectFit) {
    style.objectFit = objectFit;
  }
  const getImage = useCallback(() => {
    if (src?.toString()?.includes('amazonaws.com')) {
      return getWebpSrc(src?.toString(), imageSize);
    } else {
      return src || '/assets/images/common/broken.webp';
    }
  }, [src]);

  const [imageLoaded, setImageLoaded] = useState(false);
  const [image, setImage] = useState(getImage());

  const handleError = () => {
    if (image == src) {
      setImage('/assets/images/common/broken.webp');
    } else {
      setImage(
        src
          .toString()
          .replace('s3.eu-central-1.amazonaws.com/', '')
          .replace('prod.images', 'prod-images')
      );
    }
  };

  useEffect(() => {
    setImage(getImage());
  }, [src]);

  return (
    <>
      {!imageLoaded && withSkeleton && (
        <ImageSkeleton imageLoaded={imageLoaded} />
      )}
      <StyledImage
        itemProp="image"
        src={image}
        style={style}
        alt={alt}
        className={className}
        onError={() => {
          handleError();
        }}
        width={width}
        height={height}
        data-cy={dataTest}
        priority={priority}
        loading={loading}
        fill={fill}
        quality={quality}
        onLoad={() => {
          setImageLoaded(true);
        }}
        placeholder={placeholder}
        sizes={sizes}
        onClick={onClick}
      />
    </>
  );
};

const StyledImage = styled(Image)<StyledImagePropsType>`
  position: relative;
  ${props =>
    props.withSkeleton &&
    `
    opacity: 0;
    animation: fadeIn 1s linear forwards;
    `}

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Skeleton = styled.div<StyledImagePropsType>`
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #dcdcdc;
  animation: pulse 1s infinite;
  overflow: hidden;
  ${props =>
    !props.imageLoaded &&
    `
    opacity: 0;
`}

  @keyframes pulse {
    0% {
      background-color: #dcdcdc;
      opacity: 1;
      transform: scale(1);
      transform-origin: center;
    }
    50% {
      background-color: #ececec;
      opacity: 1;
      transform-origin: center;
    }
    100% {
      background-color: #dcdcdc;
      opacity: 1;
      transform: scale(1);
      transform-origin: center;
    }
  }
`;

export default ImageWrapper;
